import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-context-api",
  "date": "2018-04-13",
  "title": "REACT CONTEXT API",
  "author": "admin",
  "tags": ["development", "javascript", "react"],
  "featuredImage": "feature.jpg",
  "excerpt": "The context API will finally become a first-class citizen with the release of React 16.3. Facebook has announced that they are going to release a stable yet revamped version of the context API."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What’s React Context API?`}</h2>
    <p>{`In some cases, you want to pass data through the component tree without having to pass the props down manually at every level. You can do this directly in React with the powerful context API.`}</p>
    <Message type='info' title='But Facebook warns us not use the Context API!' content='The React documentation used to warn us not to use the context API given it was unstable, and now this not the case anymore. The Context API has a new makeover now and it is not an experimental framework anymore.' mdxType="Message" />
    <p>{`Before diving deep into the nitty-gritty of this new reformed API, let’s find out why we need the Context API.`}</p>
    <h2>{`Why use context?`}</h2>
    <p>{`You may have experienced having to pass down props from parent components to the child components even though they are not useful for the parent. This is called prop drilling and it can get super annoying. Also if you had to move around your components, this can get even more troublesome.`}</p>
    <p>{`What if there’s a way of accessing your data without having to pass it down as props? Well if you are using Redux, you are implicitly using context. Because the `}<inlineCode parentName="p">{`<Provider>`}</inlineCode>{` component is using context itself to put the data into the context and the connect component pulls the data out of the context.`}</p>
    <h2>{`Born again Context`}</h2>
    <p>{`Facebook has announced that they are going to release a stable yet revamped version of the context API.`}</p>
    <p>{`Let’s look at how we can use this shiny new API in code.`}</p>
    <p>{`Create a file called `}<inlineCode parentName="p">{`PostContext.js`}</inlineCode>{`. With the createContext from React package, create a context variable as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createContext } from 'react';
 
const PostContext = createContext([
  {
    title: 'Title',
    content: 'Content',
    author: 'Author'
  }
]);
 
export const PostProvider = PostContext.Provider;
export const PostConsumer = PostContext.Consumer;
`}</code></pre>
    <p>{`Here we are exporting both the provider and the consumer, that gets created. The constructor of `}<inlineCode parentName="p">{`createContext`}</inlineCode>{` is taking an object for the purpose of the initialisation.`}</p>
    <h2>{`Bootstrap records`}</h2>
    <p>{`We need to create a bootstrapper for our posts and a function that loads those posts. So let’s create that file next.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const posts = [
  {
    id: '0001',
    title: 'React Context API',
    content: 'New React API Context is finally ready',
    author: 'Sean Amarasinghe'
  },
  {
    id: '0002',
    title: 'Redux Sagas',
    content: 'How to implement Redux sagas',
    author: 'Harold Chang'
  },
  {
    id: '0003',
    title: 'Webpack 4',
    content: 'Changes in Webpack 4',
    author: 'John McMillan'
  }
];
 
export const loadPosts = () => Promise.resolve(posts);
`}</code></pre>
    <p>{`Now we have the data we need to render, let’s look at how we can create the components we need to display the posts.`}</p>
    <h2>{`Components`}</h2>
    <p>{`Let’s start by creating a component for displaying posts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { PostConsumer } from './PostContext';
 
const Content = () => (
  <div className="main">
    <PostConsumer>
      {context =>
        context.map(({ id, title, content, author }) => (
          <div key={id}>
            <h1>{title}</h1>
            <p>{content}</p>
            <p>{author}</p>
            <hr />
          </div>
        ))
      }
    </PostConsumer>
  </div>
);
 
export default Content;
`}</code></pre>
    <p>{`Now we have the posts, let’s create a container for this component`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Content from './Content';
 
const Page = () => (
  <div className="container">
    <Content />
  </div>
);
 
export default Page;
`}</code></pre>
    <p>{`Now we have the container, we can easily render in our app.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import Page from './Page';
import { loadPosts } from './bootstrapper';
import { PostProvider } from './PostContext';
 
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center'
};
 
class App extends React.Component {
  state = { posts: [] };
 
  componentDidMount() {
    loadPosts().then(posts => this.setState({ posts }));
  }
 
  render() {
    return (
      <div style={styles}>
        // highlight-next-line
        <PostProvider value={this.state.posts}>
          <Page />
        </PostProvider>
      </div>
    );
  }
}
 
ReactDOM.render(<App />, document.getElementById('root'));
`}</code></pre>
    <p>{`We are passing in the data we need to the `}<inlineCode parentName="p">{`PostProvider`}</inlineCode>{` component. This data will be again retrieved by the `}<inlineCode parentName="p">{`PostConsumer`}</inlineCode>{` component inside the Content.js component.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`React will soon remove the warnings for using context API and this is great news for refactoring existing components to use the Context API instead of props drilling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      